import React, { useRef } from "react";
import Slider from "react-slick";

import "../styles/slick.css";
import "../styles/slick-theme.css";

const Carousel = ({ sliderImages, settings, isNews }) => {
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className={`overflow-hidden relative ${isNews && "mb-10"}`}>
      {/* SLIDER */}
      <Slider ref={sliderRef} {...settings}>
        {sliderImages.map((img, index) => (
          <img
            key={index}
            src={img.src}
            alt={img.alt}
            className={`${
              !isNews &&
              "cursor-pointer transition-transform duration-700 ease-in-out transform md:hover:-translate-y-10"
            } z-100`}
          />
        ))}
      </Slider>
      {sliderImages.length > 1 ? (
        /* ARROW BUTTONS */
        <>
          <button
            onClick={previous}
            className={`${
              isNews && "border-2"
            } left-arrow absolute top-1/2 transform -translate-y-1/2 left-8 px-4 py-3 w-[3rem] h-[3rem] bg-white font-montserrat rounded-full z-10 hidden lg:block opacity-50`}
          >
            <img src="/img/forward-arrow1.svg" alt="left" />
          </button>
          <button
            onClick={next}
            className={`${
              isNews && "border-2"
            } right-arrow absolute top-1/2 transform -translate-y-1/2 right-8 px-4 py-3 w-[3rem] h-[3rem] bg-white font-montserrat rounded-full z-10 hidden lg:block opacity-50`}
          >
            <img src="/img/forward-arrow2.svg" alt="right" />
          </button>
        </>
      ) : null}
    </div>
  );
};

export default Carousel;
